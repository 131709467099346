/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./footer.component";
var styles_FooterComponent = [];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "link"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.url; var currVal_1 = (_v.context.$implicit.tooltip || _v.context.$implicit.title); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_2); }); }
function View_FooterComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "footer-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FooterComponent_2)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.children; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_FooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "grid-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Powered by Google \u00A92010-2018. \u4EE3\u7801\u6388\u6743\u65B9\u5F0F\uFF1A"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [["href", "license"], ["title", "License text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["MIT-style License"])), (_l()(), i0.ɵted(-1, null, [". \u6587\u6863\u6388\u6743\u65B9\u5F0F\uFF1A"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "a", [["href", "http://creativecommons.org/licenses/by/4.0/"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["CC BY 4.0"])), (_l()(), i0.ɵted(-1, null, [".\n"])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, [" \u5F53\u524D\u7248\u672C\uFF1A", ".\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nodes; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.versionInfo == null) ? null : _co.versionInfo.full); _ck(_v, 12, 0, currVal_1); }); }
export function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 49152, null, 0, i2.FooterComponent, [], null, null)], null, null); }
var FooterComponentNgFactory = i0.ɵccf("aio-footer", i2.FooterComponent, View_FooterComponent_Host_0, { nodes: "nodes", versionInfo: "versionInfo" }, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
