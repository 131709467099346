/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./top-menu.component";
var styles_TopMenuComponent = [".nav-link.highlight[_ngcontent-%COMP%] {\n      color: yellow;\n    }"];
var RenderType_TopMenuComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_TopMenuComponent, data: {} });
export { RenderType_TopMenuComponent as RenderType_TopMenuComponent };
function View_TopMenuComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["class", "nav-link"]], [[2, "highlight", null], [8, "href", 4], [8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "nav-link-inner"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.highlight; var currVal_1 = _v.context.$implicit.url; var currVal_2 = _v.context.$implicit.title; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_3); }); }
export function View_TopMenuComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["role", "navigation"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TopMenuComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nodes; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TopMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-top-menu", [], null, null, null, View_TopMenuComponent_0, RenderType_TopMenuComponent)), i0.ɵdid(1, 49152, null, 0, i2.TopMenuComponent, [], null, null)], null, null); }
var TopMenuComponentNgFactory = i0.ɵccf("aio-top-menu", i2.TopMenuComponent, View_TopMenuComponent_Host_0, { nodes: "nodes" }, {}, []);
export { TopMenuComponentNgFactory as TopMenuComponentNgFactory };
